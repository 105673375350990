<template>
  <div class="dropdown">
    <button
      data-testid="dropdown-button"
      :class="{ open: actionButtonState }"
      @click="changeState(!actionButtonState)"
      @blur="changeState(false)"
    >
      <slot />
    </button>
    <ul
      class="dropdown-content"
      :class="[{ active: actionButtonState }, direction]"
    >
      <li
        v-for="action in dropdownActions"
        :key="action.Label"
        :data-testid="action.EmitAction"
        @click="onDropdownItemClick(action.EmitAction)"
      >
        {{ action.Label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    dropdownActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    direction: {
      type: String,
      default: '',
    },
  },
  emits: ['DROPDOWN_SELECTED'],
  data() {
    return {
      actionButtonState: false,
    };
  },
  methods: {
    onDropdownItemClick(emitAction) {
      this.changeState(false);
      this.$emit('DROPDOWN_SELECTED', emitAction);
    },
    changeState: function (display) {
      if (display && this.dropdownActions && this.dropdownActions.length > 0) {
        this.actionButtonState = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var that = this;
        setTimeout(function () {
          that.actionButtonState = false;
        }, 250);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  color: $color-text-secondary;
  line-height: normal;

  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    color: inherit;
    font: inherit;

    &:hover i {
      color: $color-text-primary;
    }

    &:hover .svg-icon {
      color: $color-text-primary;
    }
  }

  button:focus {
    outline: none;
  }

  button * {
    cursor: inherit;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  button i {
    padding: 5px;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  button .svg-icon {
    padding: 5px;
    display: flex;
  }

  button.open i,
  button.open .svg-icon {
    color: $color-text-secondary;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  button.open i {
    outline: #bababa solid 1px;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  button.open .svg-icon {
    outline: #bababa solid 1px;
  }

  ul {
    transition: 0.2s;
  }

  ul.dropdown-content {
    line-height: normal;
    opacity: 0;
    right: 0;
    position: absolute;
    z-index: 1000;
    min-width: 200px;
    padding: 0;
    margin: 0.25rem 0 0;
    list-style: none;
    background-color: #3a3a3f;
    background-clip: padding-box;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.3px;
    text-align: left;
    color: $color-text-secondary;
    border-radius: 4px;
    pointer-events: none;
    transition:
      opacity 0.2s ease-out,
      transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
    box-shadow: 0 3px 10px #0003;

    &.up {
      bottom: 100%;
    }

    &.left {
      right: 0;
    }

    &.active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    li {
      cursor: pointer;
      pointer-events: none;
      padding: 10px 10px 10px 20px;
      transition:
        background-color 0.2s ease-out,
        color 0.2s ease-out;
    }

    li:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    li:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    li:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: $hover-text-secondary;
    }

    &.active li {
      pointer-events: all;
    }
  }
}
</style>
