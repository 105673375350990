<template>
  <div class="va-menu">
    <slot name="va-menu-item" />
  </div>
</template>

<style lang="scss">
.va-menu {
  height: 39px;
  border-radius: 100px;
  background-color: #4b4b51;
  float: right;
  margin-right: 70px;

  a {
    display: inline-block;
    border-radius: 100px;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    vertical-align: middle;
    padding: 10px;
    height: 19px;
    background-color: #4b4b51;
    color: $color-text-secondary;

    &:not(:first-child),
    &:not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      float: left;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      float: right;
    }
  }

  .router-link-exact-active {
    background-color: $color-ui-primary;
    color: $active-text-secondary;
  }
}
</style>
