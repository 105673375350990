<template>
  <div class="va-input-container" :class="{ disabled: disabled }">
    <input
      :class="['input', { 'with-label': label }]"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :maxlength="maxLength"
      data-testid="va-input"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="(focus = true), $emit('focus')"
      @blur="(focus = false), $emit('blur')"
      @keyup="$emit('keyup', $event)"
    />
    <div :class="['label', { focus: focus || modelValue || modelValue === 0 }]">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  emits: ['update:modelValue', 'blur', 'focus', 'keyup'],
  data() {
    return {
      focus: false,
    };
  },
};
</script>

<style lang="scss">
.va-input-container {
  width: 100%;
  position: relative;

  .input {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-text-primary;
    height: 39px;
    width: calc(100% - 15px);
    background-color: #4b4b51;
    border: none;
    padding: 0 0 0 15px;

    &:focus {
      outline: none;
    }

    &.with-label {
      height: 24px;
      padding: 12px 0 3px 15px;
    }
  }

  input::placeholder {
    color: $color-text-secondary;
    opacity: 0.7;
  }

  &.disabled {
    pointer-events: none;

    .input {
      color: $color-text-secondary;
    }
  }

  .label {
    position: absolute;
    left: 15px;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);
    transition: all 0.2s;
    pointer-events: none;

    &.focus {
      font-size: 10px;
      top: 5px;
      transform: none;
    }
  }
}
</style>
