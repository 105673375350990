<template>
  <va-messages position="menu" />
  <router-view
    name="fullscreen"
    class="main-full-container"
    :style="cssVariables"
  />

  <router-view name="side-bar" />

  <router-view
    id="main-container"
    class="main-container container"
    :class="{ noselect: dragging }"
    :style="cssVariables"
  />

  <SwitchClientOverlay />
</template>

<script>
import { mapGetters } from 'vuex';
import Env from '@/utils/env';
import SwitchClientOverlay from '@/components/overlays/SwitchClientOverlay.vue';

export default {
  name: 'SignagePlayer',
  components: {
    SwitchClientOverlay,
  },
  computed: {
    ...mapGetters({
      pinned: 'pinned',
      drawer: 'drawer',
      sideDrawerWidth: 'sideDrawerWidth',
      dragging: 'dragging',
      isSidebarCollapsed: 'isSidebarCollapsed',
    }),

    cssVariables() {
      return { '--side-drawer-width': `${this.sideDrawerWidth}px` };
    },
  },
  watch: {
    isSidebarCollapsed: {
      immediate: true,
      handler() {
        const app = document.querySelector('#app-signage');
        if (this.isSidebarCollapsed) {
          app.classList.add('sidebar-collapsed');
        } else {
          app.classList.remove('sidebar-collapsed');
        }
      },
    },
    drawer() {
      if (!this.drawer) {
        document.querySelector('#app-signage').classList.remove('drawer');
      } else {
        document.querySelector('#app-signage').classList.add('drawer');
      }
    },
    pinned: {
      immediate: true,
      handler() {
        if (!this.pinned) {
          document.querySelector('#app-signage').classList.remove('pinned');
        } else {
          document.querySelector('#app-signage').classList.add('pinned');
        }
      },
    },
  },
  created() {
    if (navigator.browserDetect.browser != 'Chrome' && Env.getEnv('PROD')) {
      this.$store.dispatch('addMessage', {
        message: {
          text: "Your browser is not supported. Use <a href='https://www.google.com/chrome'>Google Chrome</a> for a fully functional experience",
          type: 'warning',
        },
      });
    }
  },
};
</script>

<style lang="scss">
@use './styles/base';
@use './styles/scroll-bar';
@use './styles/variables/colors';

#app-signage {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-text-primary;
  height: 100%;

  .main-container {
    height: 100%;
    width: 100%;
    padding-left: 20rem;
    padding-right: 2rem;
    overflow: scroll;
    box-sizing: border-box;
    transition: padding-left 0.2s ease-out;
    will-change: padding-left;

    &.main-view {
      min-width: 76rem;
    }
  }

  .main-full-container {
    height: 100%;
  }

  &.sidebar-collapsed {
    .main-container {
      padding-left: 6.75rem;

      &.main-view {
        min-width: 63rem;
      }
    }
  }

  &.drawer {
    .main-container,
    .main-full-container {
      padding-right: 3.1rem;
    }

    &.pinned {
      .main-container,
      .main-full-container {
        padding-right: var(--side-drawer-width);
      }

      .main-container {
        min-width: calc(var(--side-drawer-width) + 74rem);
      }

      &.sidebar-collapsed {
        .main-container {
          min-width: calc(var(--side-drawer-width) + 61rem);
        }
      }
    }
  }
}
</style>
