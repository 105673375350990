export const errorCodes = {
  // Generic
  // ------------------------------
  GENERIC_APPLICATION_ERROR: 'generic.errors.applicationError',
  GENERIC_ATTRIBUTE_DUPLICATE: 'generic.errors.attributeDuplicate',
  GENERIC_ATTRIBUTE_EMPTY: 'generic.errors.attributeEmpty',
  GENERIC_ATTRIBUTE_NOT_FOUND: 'generic.errors.attributeNotFound',
  GENERIC_BASICS_EMPTY: 'generic.errors.basicsEmpty',
  GENERIC_PARAMETERS_EMPTY: 'generic.errors.parametersEmpty',
  GENERIC_PARAMETERS_ERROR: 'generic.errors.parametersError',
  GENERIC_FILTER_CONDITION_EMPTY: 'generic.errors.filterConditionEmpty',
  GENERIC_FILTER_CONDITION_ERROR: 'generic.errors.filterConditionError',
  GENERIC_FILTER_DUPLICATE: 'generic.errors.filterDuplicate',
  GENERIC_SCHEDULING_EMPTY: 'generic.errors.schedulingEmptyOrNotFound',
  GENERIC_SCHEDULING_NAME_DUPLICATE: 'generic.errors.schedulingNameDuplicate',
  GENERIC_SCHEDULING_END_DATE_BEFORE_START_DATE:
    'generic.errors.schedulingEndDateBeforeStartDate',
  GENERIC_FILE_EMPTY: 'generic.errors.fileEmptyOrNotFound',
  GENERIC_FILE_NOT_FOUND: 'generic.errors.fileEmptyOrNotFound',
  GENERIC_FILE_UPLOAD_CORRUPT: 'generic.errors.fileUploadCorrupt',
  GENERIC_FILE_UPLOAD_EMPTY: 'generic.errors.fileUploadEmpty',
  GENERIC_FILE_EXCEEDS_MAXLENGTH: 'generic.errors.fileExceedsMaxlength',
  GENERIC_GREATER_THAN_ZERO: 'generic.errors.greaterThanZero',

  // Broadcasts
  // ------------------------------
  BROADCAST_NOT_FOUND: 'broadcast.errors.broadcastNotFound',
  BROADCAST_DUPLICATE: 'broadcast.errors.broadcastDuplicate',
  BROADCAST_NAME_DUPLICATE: 'broadcast.errors.broadcastNameDuplicate',
  BROADCAST_NAME_EMPTY: 'broadcast.errors.broadcastNameEmpty',
  BROADCAST_NAME_EXCEEDS_MAXLENGTH:
    'broadcast.errors.broadcastNameExceedsMaxlength',
  BROADCAST_EXTERNAL_ID_DUPLICATE:
    'broadcast.errors.broadcastExternalIdDuplicate',
  BROADCAST_CODE_OR_EXTERNAL_ID_DUPLICATE:
    'broadcast.errors.broadcastCodeOrExternalIdDuplicate',
  BROADCAST_REMOVE_MEDIA_CODE_EMPTY:
    'broadcast.errors.broadcastRemoveMediaCodeEmpty',
  BROADCAST_PARAMETERS_EMPTY: 'broadcast.errors.broadcastParametersEmpty',
  // -> Communication mix
  BROADCAST_COMMUNICATION_MIX_DUPLICATE:
    'broadcast.errors.broadcastCommunicationMixDuplicate',
  BROADCAST_COMMUNICATION_MIX_NAME_DUPLICATE:
    'broadcast.errors.broadcastCommunicationMixNameDuplicate',
  BROADCAST_COMMUNICATION_MIX_EMPTY:
    'broadcast.errors.broadcastCommunicationMixEmptyOrNotFound',
  // -> Groups
  BROADCAST_GROUP_EMPTY: 'broadcast.errors.broadcastGroupEmptyOrNotFound',
  BROADCAST_GROUP_NOT_FOUND: 'broadcast.errors.broadcastGroupEmptyOrNotFound',
  // -> References
  BROADCAST_REFERENCE_EMPTY:
    'broadcast.errors.broadcastReferenceEmptyOrNotFound',
  BROADCAST_REFERENCE_NOT_FOUND:
    'broadcast.errors.broadcastReferenceEmptyOrNotFound',
  BROADCAST_COMMUNICATION_MIX_REFERENCE_EMPTY:
    'broadcast.errors.broadcastCommunicationMixReferenceEmptyOrNotFound',
  BROADCAST_COMMUNICATION_MIX_REFERENCE_NOT_FOUND:
    'broadcast.errors.broadcastCommunicationMixReferenceEmptyOrNotFound',

  // Media
  // ------------------------------
  MEDIA_EMPTY: 'media.errors.mediaEmptyOrNotFound',
  MEDIA_NOT_FOUND: 'media.errors.mediaEmptyOrNotFound',
  MEDIA_CODE_EMPTY: 'media.errors.mediaCodeEmptyOrNotFound',
  MEDIA_LIST_EMPTY: 'media.errors.mediaListEmptyOrNotFound',
  MEDIA_LIST_NOT_FOUND: 'media.errors.mediaListEmptyOrNotFound',
  MEDIA_FILE_DUPLICATE: 'media.errors.mediaFileDuplicate',
  MEDIA_FILE_EXCEEDS_MAX_FILESIZE: 'media.errors.mediaFileExceedsMaxFilesize',
  MEDIA_FILE_EXCEEDS_MAX_PIXELSIZE: 'media.errors.mediaFileExceedsMaxPixelsize',
  MEDIA_IMAGE_FILE_EXCEEDS_MAX_FILESIZE:
    'media.errors.mediaImageFileExceedsMaxFilesize',
  // -> Templates/Signs
  MEDIA_SIGN_DUPLICATE: 'media.errors.mediaSignDuplicate',
  MEDIA_SIGN_NOT_FOUND: 'media.errors.mediaSignNotFound',
  MEDIA_SIGN_NAME_EXCEEDS_MAXLENGTH:
    'media.errors.mediaSignNameExceedsMaxlength',
  MEDIA_SIGN_NAME_EMPTY: 'media.errors.mediaSignNameEmpty',
  // -> References
  MEDIA_REFERENCE_EMPTY: 'media.errors.mediaReferenceEmptyOrNotFound',
  MEDIA_REFERENCE_NOT_FOUND: 'media.errors.mediaReferenceEmptyOrNotFound',
  AI_IMAGE_GENERATION_ERROR: 'media.errors.aiImageGenerationError',

  // Tags
  // ------------------------------
  TAG_EMPTY: 'tags.errors.tagEmptyOrNotFound',
  TAG_NOT_FOUND: 'tags.errors.tagEmptyOrNotFound',
  TAGS_EMPTY: 'tags.errors.tagsEmptyOrNotFound',
  TAGS_NOT_FOUND: 'tags.errors.tagsEmptyOrNotFound',
  TAG_DIMENSIONS_EMPTY: 'tags.errors.tagDimensionsEmptyOrNotFound',
  TAG_TYPE_NOT_FOUND: 'tags.errors.tagTypeEmptyOrNotFound',
  TAG_CHARACTER_NOT_ALLOWED: 'tags.errors.tagCharacterNotAllowed',
  // -> References
  TAG_REFERENCE_EMPTY: 'tags.errors.tagReferenceEmptyOrNotFound',
  TAG_REFERENCE_NOT_FOUND: 'tags.errors.tagReferenceEmptyOrNotFound',
  TAG_NAME_EMPTY: 'tags.errors.tagNameEmpty',
  TAG_DUPLICATE_GENERIC: 'tags.errors.tagDuplicate',
  TAG_DUPLICATE_CONTENT_TYPE: 'tags.errors.contentTypeTagDuplicate',
  TAG_IN_USE_GENERIC: 'tags.errors.tagInUse',
  TAG_IN_USE_CONTENT_TYPE: 'tags.errors.contentTypeTagInUse',

  // Triggers
  // ------------------------------
  TRIGGER_DUPLICATE: 'triggers.errors.triggerDuplicate',
  TRIGGER_EMPTY: 'triggers.errors.triggersEmptyOrNotFound',
  TRIGGER_NOT_FOUND: 'triggers.errors.triggersEmptyOrNotFound',
  TRIGGERS_EMPTY: 'triggers.errors.triggersEmptyOrNotFound',
  TRIGGERS_NOT_FOUND: 'triggers.errors.triggersEmptyOrNotFound',
  // -> References
  TRIGGER_REFERENCE_EMPTY: 'triggers.errors.triggerReferenceEmptyOrNotFound',
  TRIGGER_REFERENCE_NOT_FOUND:
    'triggers.errors.triggerReferenceEmptyOrNotFound',

  // Players
  // ------------------------------
  PLAYER_DUPLICATE: 'players.errors.playerDuplicate',
  PLAYER_EMPTY: 'players.errors.playerEmptyOrNotFound',
  PLAYER_NOT_FOUND: 'players.errors.playerEmptyOrNotFound',
  PLAYERS_EMPTY: 'players.errors.playersEmptyOrNotFound',
  PLAYERS_NOT_FOUND: 'players.errors.playersEmptyOrNotFound',
  PLAYER_CODE_EMPTY: 'players.errors.playerCodeEmptyOrNotFound',
  PLAYER_CODE_NOT_FOUND: 'players.errors.playerCodeEmptyOrNotFound',
  PLAYER_ONBOARDING_NOT_FOUND: 'players.errors.playerOnboardingNotFound',
  PLAYER_INSTRUCTIONS_NOT_FOUND:
    'players.errors.playerInstructionsEmptyOrNotFound',
  PLAYER_APPLICATION_NOT_FOUND:
    'players.errors.playerApplicationEmptyOrNotFound',
  PLAYER_UNIQUE_ID_NOT_FOUND: 'players.errors.playerUniqueIdEmptyOrNotFound',
  PLAYER_SCREENSHOT_NOT_FOUND: 'players.errors.playerScreenshotEmptyOrNotFound',
  PLAYER_NAME_DUPLICATE: 'players.errors.playerNameDuplicate',
  PLAYER_NAME_EXCEEDS_MAXLENGTH: 'players.errors.playerNameExceedsMaxlength',
  PLAYER_SCREEN_ID_EXCEEDS_MAXLENGTH:
    'players.errors.playerScreenIdExceedsMaxlength',
  PLAYER_SCREEN_ID_DUPLICATE: 'players.errors.playerScreenIdDuplicate',
  PLAYER_ALIAS_EXCEEDS_MAXLENGTH: 'players.errors.playerAliasExceedsMaxlength',

  // -> References
  PLAYER_REFERENCE_EMPTY: 'players.errors.playerReferenceEmptyOrNotFound',
  PLAYER_REFERENCE_NOT_FOUND: 'players.errors.playerReferenceEmptyOrNotFound',

  // Playout
  // ------------------------------
  PLAYOUT_EMPTY: 'players.errors.playoutEmptyOrNotFound',
  PLAYOUT_NOT_FOUND: 'players.errors.playoutEmptyOrNotFound',
  PLAYOUT_INSTRUCTIONS_NOT_FOUND:
    'players.errors.playoutInstructionsEmptyOrNotFound',
  PLAYOUT_FORMAT_DUPLICATE: 'players.errors.playoutFormatDuplicate',
  PLAYOUT_FORMAT_DIMENSIONS_EMPTY:
    'players.errors.playoutDimensionsEmptyOrNotFound',
  PLAYOUT_FORMAT_LAYER_EMPTY:
    'players.errors.playoutFormatLayerEmptyOrNotFound',
  PLAYOUT_FORMAT_LAYER_NOT_FOUND:
    'players.errors.playoutFormatLayerEmptyOrNotFound',
  PLAYOUT_LAYER_CHANNEL_DUPLICATE:
    'players.errors.playoutLayerChannelDuplicate',
  PLAYOUT_LAYER_CHANNEL_EMPTY:
    'players.errors.playoutLayerChannelEmptyOrNotFound',
  PLAYOUT_LAYER_CHANNEL_NOT_FOUND:
    'players.errors.playoutLayerChannelEmptyOrNotFound',
  // -> References
  PLAYOUT_REFERENCE_EMPTY: 'players.errors.playoutReferenceEmpty',
  PLAYOUT_LAYER_CHANNEL_REFERENCE_EMPTY:
    'players.errors.playoutLayerChannelReferenceEmptyOrNotFound',
  PLAYOUT_LAYER_CHANNEL_REFERENCE_NOT_FOUND:
    'players.errors.playoutLayerChannelReferenceEmptyOrNotFound',

  // Location
  // ------------------------------
  LOCATION_EMPTY: 'location.errors.locationEmptyOrNotFound',
  LOCATION_NOT_FOUND: 'location.errors.locationEmptyOrNotFound',
  LOCATION_NAME_DUPLICATE: 'location.errors.locationNameDuplicate',
  LOCATION_NAME_MANDATORY: 'location.errors.locationNameMandatory',
  LOCATION_NAME_EXCEEDS_MAXLENGTH:
    'location.errors.locationNameExceedsMaxlength',
  LOCATION_ID_DUPLICATE: 'location.errors.locationIdDuplicate',
  LOCATION_CODE_EMPTY: 'location.errors.locationCodeEmptyOrNotFound',
  LOCATION_CODE_NOT_FOUND: 'location.errors.locationCodeEmptyOrNotFound',
  LOCATION_DIMENSIONS_EMPTY:
    'location.errors.locationDimensionsEmptyOrNotFound',
  // -> References
  LOCATION_REFERENCE_EMPTY: 'location.errors.locationReferenceEmptyOrNotFound',
  LOCATION_REFERENCE_NOT_FOUND:
    'location.errors.locationReferenceEmptyOrNotFound',

  // Clients
  // ------------------------------
  CLIENT_EMPTY: 'overview.clients.errors.clientEmptyOrNotFound',
  CLIENT_NOT_FOUND: 'overview.clients.errors.clientEmptyOrNotFound',
  CLIENT_ID_DUPLICATE: 'overview.clients.errors.clientIdDuplicate',
  CLIENT_NAME_DUPLICATE: 'overview.clients.errors.clientNameDuplicate',
  CLIENT_NAME_MANDATORY: 'overview.clients.errors.clientNameMandatory',
  CLIENT_NAME_EXCEEDS_MAXLENGTH:
    'overview.clients.errors.clientNameExceedsMaxlength',

  // Customers
  // ------------------------------
  CUSTOMER_NAME_MANDATORY: 'overview.customers.errors.customerNameMandatory',
  CUSTOMER_NAME_EXCEEDS_MAXLENGTH:
    'overview.customers.errors.customerNameExceedsMaxlength',
  CUSTOMER_NAME_DUPLICATE: 'overview.customers.errors.customerNameDuplicate',
  CUSTOMER_ID_DUPLICATE: 'overview.customers.errors.customerIdDuplicate',

  // Account
  // ------------------------------
  ACCOUNT_NAME_DUPLICATE: 'account.errors.accountNameDuplicate',
  ACCOUNT_ID_DUPLICATE: 'account.errors.accountIdDuplicate',
  ACCOUNT_NAME_MANDATORY: 'account.errors.accountNameMandatory',
  ACCOUNT_NAME_EXCEEDS_MAXLENGTH: 'account.errors.accountNameExceedsMaxlength',

  // Users
  // ------------------------------
  USER_NOT_FOUND: 'users.errors.userNotFound',
  USER_TYPE_NOT_FOUND: 'users.errors.userTypeNotFound',
  USER_EMPTY: 'users.errors.userEmpty',
  USER_CODE_EMPTY: 'users.errors.userCodeEmpty',
  USER_DIMENSIONS_EMPTY: 'users.error.userDimensionsEmpty',
  USER_UNAUTHORIZED: 'users.errors.userUnauthorized',
  USER_NO_ACCESS: 'users.errors.userNoAccess',
  // -> Form
  USER_ID_DUPLICATE: 'users.errors.userIdDuplicate',
  USER_EMAIL_DUPLICATE: 'users.errors.userEmailDuplicate',
  USER_EMAIL_INVALID: 'users.errors.userEmailInvalid',
  USER_NAME_DUPLICATE: 'users.errors.userNameDuplicate',
  USER_NAME_MANDATORY: 'users.errors.userNameMandatory',
  USER_FIRST_NAME_EXCEEDS_MAXLENGTH:
    'users.errors.userFirstNameExceedsMaxlength',
  USER_LAST_NAME_EXCEEDS_MAXLENGTH: 'users.errors.userLastNameExceedsMaxlength',
  // -> Accounts
  USER_ACCOUNT_EMPTY: 'users.errors.userAccountEmpty',
  USER_ACCOUNT_NOT_FOUND: 'users.errors.userAccountNotFound',
  USER_DEFAULT_ACCOUNT_NOT_FOUND: 'users.errors.userDefaultAccountNotFound',
  // -> Clients
  USER_NO_ACCESS_TO_CLIENT: 'users.errors.userNoAccessToClient',
  // -> Application
  USER_APPLICATION_EMPTY: 'users.errors.userApplicationEmptyOrNotFound',
  USER_APPLICATION_NOT_FOUND: 'users.errors.userApplicationEmptyOrNotFound',
  // -> Roles
  USER_ROLE_DUPLICATE: 'users.errors.userRoleDuplicate',
  USER_ROLE_NOT_FOUND: 'users.errors.userRoleNotFound',
  // -> References
  USER_REFERENCE_EMPTY: 'users.errors.userReferenceEmptyOrNotFound',
  USER_REFERENCE_NOT_FOUND: 'users.errors.userReferenceEmptyOrNotFound',
  USER_ACCOUNT_REFERENCE_EMPTY:
    'users.errors.userAccountReferenceEmptyOrNotFound',
  USER_ACCOUNT_REFERENCE_NOT_FOUND:
    'users.errors.userAccountReferenceEmptyOrNotFound',
  // -> Invite
  USER_NO_INVITE_FOR_VA_USERS: 'users.errors.noInviteForVisualArtUsers',

  // Excel
  // ------------------------------
  EXCEL_LOCATION_SHEET_NOT_FOUND: 'excel.errors.locationSheetNotFound',
  EXCEL_PLAYERS_SHEET_NOT_FOUND: 'excel.errors.playersSheetNotFound',
  EXCEL_CLIENT_SHEET_NOT_FOUND: 'excel.errors.clientSheetNotFound',
  EXCEL_PLAYERFORMATS_SHEET_NOT_FOUND:
    'excel.errors.playerFormatsSheetNotFound',
  EXCEL_PLAYERSETTINGS_SHEET_NOT_FOUND:
    'excel.errors.playerSettingsSheetNotFound',
  EXCEL_LOCATIONSETTINGS_SHEET_NOT_FOUND:
    'excel.errors.locationSettingsSheetNotFound',

  // Player Software
  // ------------------------------
  PLAYER_SOFTWARE_GROUP_NAME_DUPLICATE:
    'overview.playerSoftware.errors.groupNameDuplicate',
};
