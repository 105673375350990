<template>
  <div class="va-view">
    <div class="tool-bar">
      <div
        :class="[{ active: layout == 'grid' }, 'left']"
        @click="layout = 'grid'"
      >
        <i class="fa fa-th-large" aria-hidden="true" />
      </div>
      <div
        :class="[{ active: layout == 'list' }, 'right']"
        @click="layout = 'list'"
      >
        <i class="fa fa-bars" aria-hidden="true" />
      </div>
    </div>

    <ul v-if="layout === 'grid'" class="grid">
      <li v-for="item in items" :key="item.mediaCode">
        <img :src="item.thumbnailUrl" />
        <p>{{ item.displayName }}</p>
      </li>
    </ul>

    <ul v-if="layout === 'list'" class="list">
      <li v-for="item in items" :key="item.mediaCode">
        <div>
          <img :src="item.thumbnailUrl" :value="item" @click="onClick(item)" />
        </div>
        <div>
          <p>{{ item.displayName }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['onImageClick'],
  data() {
    return {
      layout: 'list',
    };
  },
  methods: {
    onClick: function (item) {
      this.$emit('onImageClick', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.va-view {
  width: 1050px;
}

.tool-bar {
  width: 84px;
  height: 39px;
  border-radius: 100px;
  background-color: #4b4b51;
  float: right;
  margin-right: 70px;
}

.tool-bar div {
  display: inline-block;
  height: 39px;
  border-radius: 100px;
  width: 42px;
}

.tool-bar .left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  float: left;
}

.tool-bar .right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  float: right;
}

.tool-bar div i {
  color: $color-text-secondary;
  margin-top: 12px;
}

.active {
  background-color: $color-ui-primary;
  color: $color-text-primary;
}

.tool-bar .active i {
  color: $color-text-primary;
}

/* Grid layout */
.grid {
  list-style: none;
  text-align: left;
  padding: 60px 0 0;
  font-family: Roboto, sans-serif;
}

.grid li {
  display: inline-block;
  position: relative;
  width: 305px;
  height: 305px;
  padding: 15px;
  color: $color-text-secondary;
}

.grid li img {
  width: 305px;
  height: 305px;
}

/* List layout */
.list {
  list-style: none;
  text-align: left;
  width: 948px;
  font-family: Roboto, sans-serif;
  padding: 60px 0 0 30px;
}

.list li {
  box-shadow: inset 0 1px 0 0 #3a3a3f;
  font-size: 13px;
  letter-spacing: 0.3px;
  text-align: left;
  color: $color-text-secondary;
}

.list li div {
  display: inline-block;
  margin: 0 auto;
  text-align: left;

  &:nth-child(2) {
    width: 45%;
  }

  &:nth-child(3) {
    width: 44%;
  }
}

.list li img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  cursor: pointer;
  pointer-events: all;
}
</style>
